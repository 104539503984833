import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, navigate } from "gatsby";
import Img from "gatsby-image";
import qs from "query-string";
import cn from 'classname';
import { useLocation } from '@reach/router';
import arraySort from 'array-sort'
import Footer from "../components/footer";
import HeaderMobile from "../components/header-mobile";
import Layout from "../components/layout";
import { mediumScreen } from "../utils/utils";
import Header from "../components/header";
import { useWindowSize } from "../utils/hook";

const Sales = ({ pageContext }) => {
  const location = useLocation()
  const { screen } = useWindowSize();
  const { products: _products } = pageContext;
  const [filterSold, setFilterSold] = useState(false)

  const queries = qs.parse(location.search);
  const onSetType = useCallback((type) => {
    if (queries.type === type) {
      const sold = queries.sold ? `?sold=${queries.sold}` : '';
      navigate(`/for-sale${sold}`);
      return
    }
    
    const sold = queries.sold ? `&sold=${queries.sold}` : '';
    navigate(`/for-sale?type=${type}${sold}`);
  }, [queries.type, queries.sold]);
  const onSetSold = useCallback(() => {
    setFilterSold(v => !v)
  },[])

  useEffect(() => {
    const type = queries.type ? `?type=${queries.type}` : '';
    const sold = filterSold ? `${type ? '&' : '?'}sold=true` : '';
    navigate(`/for-sale${type}${sold}`);
  }, [queries.type, queries.sold, filterSold])
  
  const products = useMemo(() => {
    let filteredProducts = arraySort(_products, 'productId', { reverse: true })
    filteredProducts = !filterSold ? filteredProducts : filteredProducts.filter(p => p.sold)
    if (!queries.type) {
      return filteredProducts
    }
    if (typeof queries.type !== 'string') {
      return filteredProducts
    }
    
    return filteredProducts.filter(p => {
      if (Array.isArray(p.type) && p.type.includes(queries.type)) {
        return true
      }
      
      return false
    })
  }, [_products, queries.type, filterSold]) 

  return (
    <Layout
      title='Westview Golf Cars | For Sale'
      description='We sell new and refurbished gas, electric, and custom golf cars. If you’re looking for a custom golf car, get in touch and we’ll build something you love.'
    >
      <HeaderMobile />
      <div className='container px-4'>
        {/* header */}
        <Header />
      </div>
      <h1
        className='bg-black text-white uppercase text-4xl text-center font-semibold mt-8'
        style={{ letterSpacing: '0.15em' }}
      >
        For sale
      </h1>
      <div>
        <p className='text-xl text-center uppercase mt-6 mb-2 tracking-widest font-semibold'>
          Filters
        </p>
        <div className='container bg-primary py-2 mb-4'>
          <div className='max-w-4xl mx-auto flex justify-around px-4 lg:px-0'>
            <button
              className={cn([
                'p-2 px-4 tracking-widest uppercase font-semibold transition duration-300',
                {
                  'bg-black text-white': queries.type !== 'electric',
                  'bg-white text-black': queries.type === 'electric',
                },
              ])}
              onClick={() => onSetType('electric')}
            >
              Electric
            </button>
            <button
              className={cn([
                'p-2 px-4 tracking-widest uppercase font-semibold transition duration-300',
                {
                  'bg-black text-white': queries.type !== 'gas',
                  'bg-white text-black': queries.type === 'gas',
                },
              ])}
              onClick={() => onSetType('gas')}
            >
              Gas
            </button>
            <button
              className={cn([
                'p-2 px-4 tracking-widest uppercase font-semibold transition duration-300',
                {
                  'bg-black text-white': queries.type !== 'custom',
                  'bg-white text-black': queries.type === 'custom',
                },
              ])}
              onClick={() => onSetType('custom')}
            >
              Custom
            </button>
            {/* <button
              className={cn([
                'p-2 px-4 tracking-widest uppercase font-semibold transition duration-300',
                {
                  'bg-black text-white': !filterSold,
                  'bg-white text-black': filterSold,
                },
              ])}
              onClick={onSetSold}
            >
              Sold
            </button> */}
          </div>
        </div>
        <div className='container p-4 flex flex-wrap justify-center md:justify-between'>
          {products.map((product) => (
            <div
              key={product.productId}
              className='mb-6 max-w-xs'
              style={{ minHeight: 368 }}
            >
              <Link
                className='w-full mx-auto'
                to={`/products/${product.productId}`}
              >
                <Img
                  fluid={product.image.fluid}
                  alt='product-image'
                  style={{ width: screen === 'sm' ? '16rem' : '18rem' }}
                />
              </Link>
              <p className={cn('text-xl p-2 text-center font-semibold h-18')}>
                {product.title || product.name}
              </p>
              <p className={cn('text-lg text-center')}>
                {Number(product.price).toLocaleString('en-US', {
                  currency: 'USD',
                  style: 'currency',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </p>
              <Link to={`/products/${product.productId}`}>
                <div className='flex justify-center mt-2'>
                  <button
                    className={cn(
                      'p-2 px-8 tracking-widest uppercase font-semibold bg-black text-white',
                    )}
                  >
                    View
                  </button>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer className='bottom-0' />
    </Layout>
  )
};

export default Sales;
